<template>
  <HeadlessTransitionRoot appear :show="isOpen" as="template">
    <HeadlessDialog as="div" class="fixed z-50" @close="closeModal">
      <HeadlessTransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </HeadlessTransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="mx-auto flex h-full min-h-full w-max items-center justify-center overflow-hidden text-center sm:p-4 md:p-12"
        >
          <HeadlessTransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <HeadlessDialogPanel
              class="container mx-auto h-full max-h-[600px] w-max min-w-[400px] max-w-xl transform overflow-hidden bg-white text-left align-middle shadow-xl transition-all"
            >
              <HeadlessDialogTitle
                as="div"
                class="relative z-50 mb-4 flex w-full items-center justify-end px-8 pb-3 pt-4 text-lg font-semibold uppercase tracking-wider text-primary after:absolute after:bottom-0 after:left-4 after:right-4 after:border-b after:border-primary sm:px-4"
              >
                <Icon
                  role="button"
                  :aria-label="$t('close')"
                  name="ic:outline-close"
                  tabindex="0"
                  size="24"
                  class="cursor-pointer"
                  @click="closeModal"
                  @keyup.enter="closeModal"
                />
              </HeadlessDialogTitle>
              <iframe
                v-if="catalog"
                :src="catalog.url"
                :title="catalog.type"
                class="absolute inset-0 h-full w-full"
              />
            </HeadlessDialogPanel>
          </HeadlessTransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue'

type Catalog = { url: string; type: string }
const isOpen = ref(false)
const catalog = ref<Catalog | null>(null)

function closeModal() {
  isOpen.value = false

  const ANIMATION_DURATION = 200
  setTimeout(() => {
    catalog.value = null
  }, ANIMATION_DURATION)
}
function openModal(newCatalog: Catalog) {
  catalog.value = newCatalog
  isOpen.value = true
}

defineExpose({
  openModal
})
</script>
