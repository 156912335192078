import type DialogCatalog from '@autobid/ui/components/common/dialog/Catalog.vue'
import type { DisplayingAuction } from '../types/components/AuctionsData'

/**
 * A Composable that allows to handle CatalogDialog with a custom trigger
 * @example
 * <script setup lang="ts">
 * const { dialogCatalogRef, onCatalogClick } = useCatalogRef(auction)
 * </script>
 * <template>
 * <DialogCatalog ref="dialogCatalogRef"/>
 * <button "@click="onCatalogClick"">Open catalog</button>
 * </template>
 * @important It's a composable so you have to initialize it at the top level of component
 */
export function useAuctionCatalog(
  auction:
    | DisplayingAuction
    | Ref<DisplayingAuction>
    | undefined
    | Ref<undefined>
) {
  const { localeProperties, locale } = useI18n()
  const dialogCatalogRef = ref<InstanceType<typeof DialogCatalog>>()

  const onCatalogClick = () => {
    const unwrappedAuction = unref(auction)
    if (!unwrappedAuction) {
      return
    }
    const { catalog } = unwrappedAuction
    if (!catalog) return
    const url = `${catalog.url}&L=${localeProperties.value.premiumId}&lang=${locale.value}`
    const catalogWithPremiumId = {
      ...catalog,
      url
    }
    dialogCatalogRef.value.openModal(catalogWithPremiumId)
  }

  return {
    dialogCatalogRef,
    onCatalogClick
  }
}
